import React, { useEffect, useRef, useState } from 'react';
import { graphql, usePreloadedQuery, type EntryPointProps } from 'react-relay';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import {
	useActiveRightSidebarState,
	useRightSidebarController,
} from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/right-sidebar-controller/index.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { ssrExperiment_ui_growthRecommendationsInProjectList_RecommendationsInProjectListQuery } from '@atlassian/jira-relay/src/__generated__/ssrExperiment_ui_growthRecommendationsInProjectList_RecommendationsInProjectListQuery.graphql';
import { PANEL_ID, SIDEBAR_WIDTH } from '../../constants.tsx';
import RecommendationsInProjectListContainer from './container/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { PANEL_ID, SIDEBAR_WIDTH } from '../../constants';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { useSetProjectListRightPanelState } from '../../services/set-right-panel-state/index.tsx';

type Queries = {
	templatesQueryRef: ssrExperiment_ui_growthRecommendationsInProjectList_RecommendationsInProjectListQuery;
};

type Props = EntryPointProps<Queries, {}, {}, {}>;

const JiraProjectsTemplateListRightPanel = ({ queries: { templatesQueryRef } }: Props) => {
	const { openRightSidebar, closeRightSidebar } = useRightSidebarController();

	const rootQueryRef =
		usePreloadedQuery<ssrExperiment_ui_growthRecommendationsInProjectList_RecommendationsInProjectListQuery>(
			graphql`
				query ssrExperiment_ui_growthRecommendationsInProjectList_RecommendationsInProjectListQuery(
					$cloudId: ID!
					$isAnonymous: Boolean!
				) @preloadable {
					jira @skip(if: $isAnonymous) {
						userPreferences(cloudId: $cloudId) @optIn(to: "JiraUserPreferences") {
							projectListRightPanelState @optIn(to: "JiraProjectListRightPanelState")
						}
						sidebarExperimentTemplates: projectListViewTemplates(
							cloudId: $cloudId
							experimentKey: "sidebarExperiment"
						) @optIn(to: "JiraProjectListViewTemplate") {
							totalCount
						}
						canCreateProject: canPerform(type: CREATE_PROJECT, cloudId: $cloudId)
							@optIn(to: "JiraAction")

						...container_growthRecommendationsInProjectList_RecommendationsInProjectList
					}
				}
			`,
			templatesQueryRef,
		);

	const { jira } = rootQueryRef;

	const isAnonymous = templatesQueryRef?.variables.isAnonymous;

	const { createAnalyticsEvent } = useAnalyticsEvents();
	useEffect(() => {
		if (isAnonymous || !jira) return;
		if (
			!(typeof jira?.sidebarExperimentTemplates?.totalCount === 'number') ||
			!(jira?.sidebarExperimentTemplates?.totalCount > 0) ||
			!jira?.userPreferences?.projectListRightPanelState ||
			!jira?.canCreateProject
		) {
			fireTrackAnalytics(createAnalyticsEvent({}), 'sidebarEligibility failed');
			return;
		}

		if (jira?.userPreferences?.projectListRightPanelState === 'OPEN') {
			openRightSidebar(PANEL_ID, SIDEBAR_WIDTH, false, true);
		}

		if (jira?.userPreferences?.projectListRightPanelState === 'CLOSED') {
			closeRightSidebar(PANEL_ID);
		}
	}, [
		openRightSidebar,
		closeRightSidebar,
		createAnalyticsEvent,
		isAnonymous,
		jira?.sidebarExperimentTemplates?.totalCount,
		jira?.userPreferences?.projectListRightPanelState,
		jira?.canCreateProject,
		jira,
	]);

	const rightSidebarCurrentState = useActiveRightSidebarState();
	const [localPanelId, setLocalPanelId] = useState<string | undefined>();
	const prevPanelIdRef = useRef<string | undefined>();
	const delayRef = useRef<NodeJS.Timeout | undefined>(undefined);
	const panelId = rightSidebarCurrentState?.panelId;

	/**
	 * This use effect is used to add (when is necessary) a delay
	 * in the panelId => localPanelId syncing
	 */
	useEffect(() => {
		clearTimeout(delayRef.current);

		if (prevPanelIdRef.current === PANEL_ID) {
			/**
			 * The right-sidebar is showing the templates panel...
			 */
			if (
				rightSidebarCurrentState &&
				panelId !== undefined &&
				!rightSidebarCurrentState.isCollapsed &&
				!rightSidebarCurrentState.isMinimised
			) {
				/**
				 * and is open, not collapsed and we want to show a panel
				 * with an ID !== PANEL_ID
				 */
				setLocalPanelId(panelId);
			} else {
				/**
				 * and we want to close it
				 */
				delayRef.current = setTimeout(() => {
					setLocalPanelId(panelId);
				}, 310);
			}
		} else {
			/**
			 * If the right sidebar is not showing the templates panel,
			 * we just keep localPanelId in sync with panelId
			 */
			setLocalPanelId(panelId);
		}

		prevPanelIdRef.current = panelId;

		return () => clearTimeout(delayRef.current);
	}, [panelId, rightSidebarCurrentState]);

	useEffect(
		() => () => {
			closeRightSidebar(PANEL_ID);
		},
		[closeRightSidebar],
	);

	if (localPanelId !== PANEL_ID) {
		return null;
	}

	if (isAnonymous) return null;

	if (!rootQueryRef?.jira) {
		throw new Error('Failed to load sidebar content');
	}

	return <RecommendationsInProjectListContainer rootQueryRef={rootQueryRef.jira} />;
};

const JiraProjectsTemplateListRightPanelContainer = (props: Props) => (
	<JSErrorBoundary
		fallback="unmount"
		id="right-sidebar-layout-component"
		packageName="jiraGrowthRecommendationsInProjectList"
	>
		<JiraProjectsTemplateListRightPanel {...props} />
	</JSErrorBoundary>
);

export default JiraProjectsTemplateListRightPanelContainer;
