import { useIntl } from '@atlassian/jira-intl';
import { useCustomSkipLink } from '@atlassian/jira-navigation-system/src/index.tsx';
import messages from './messages.tsx';

export const JiraProjectListSidePanelSkipLink = () => {
	const { formatMessage } = useIntl();

	useCustomSkipLink(
		'jira-project-list-side-panel',
		formatMessage(messages.skipToRightSidePanel),
		4,
	);

	return null;
};
