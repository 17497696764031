import type { CrossFlowExtensions } from '@atlassiansox/cross-flow-base-types';
import {
	Behaviours,
	createProvisioningPageExtensions,
	createRecommendationContextExtensions,
	Prompts,
	TargetTabs,
} from '@atlassiansox/cross-flow-extensions';

/**
 * Builds crossflow extensions by merging provisioning page extensions and recommendation context extensions.
 *
 * @param args - The arguments for building crossflow extensions.
 * @param args.templateId - Template long key.
 * @param args.recommendationSessionId - The recommendation session ID.
 * @param args.templateType - Template short key.
 * @returns The merged crossflow extensions.
 */
export const buildCrossflowExtensions = (args: {
	templateId: string;
	recommendationSessionId: string;
	templateType: string;
}): CrossFlowExtensions => {
	const { templateId, recommendationSessionId, templateType } = args;
	const provisioningExtensions = createProvisioningPageExtensions({
		actions: {
			primary: {
				behaviour: Behaviours.LAND,
				prompt: Prompts.NEXT,
				options: {
					targetTab: TargetTabs.SAME,
					urlParams: { templateId: templateType }, // * CFFE can handle the short key
				},
			},
		},
	});

	const recoExtensions = createRecommendationContextExtensions({
		recommendationSessionId,
		entityId: templateId, // * cb requires the long key
	});
	const mergedExtensions: CrossFlowExtensions = {};
	Object.assign(mergedExtensions, provisioningExtensions, recoExtensions);
	return mergedExtensions;
};
