import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	skipToRightSidePanel: {
		id: 'growth-recommendations-in-project-list.skip-link.skip-to-right-side-panel',
		defaultMessage: 'Skip to Side Panel',
		description:
			'Accessibility title for skipping to the Jira Project List side panel section of Jira. Shows when tabbing first time on a page',
	},
});
