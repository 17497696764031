import { IT_SERVICE_MANAGEMENT_COMPANY_MANAGED_KEY } from '@atlassian/jira-common-constants/src/project-template-type-keys.tsx';
import { fg } from '@atlassian/jira-feature-gating';

export const checkShouldPromptJsmTrial = ({
	templateKey,
	isProductLicensed,
	canCreate,
}: {
	templateKey: string;
	isProductLicensed: boolean;
	canCreate: boolean;
}) => {
	return {
		shouldPromptJsmTrial:
			templateKey === IT_SERVICE_MANAGEMENT_COMPANY_MANAGED_KEY &&
			isProductLicensed &&
			!canCreate &&
			fg('jsm_it_service_management_advanced_template'),
	};
};
