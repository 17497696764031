/**
 * @generated SignedSource<<9a414dc9b764a5f1d0f43a1e32bbd091>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type preview_growthRecommendationsInProjectList_TemplatePreview$data = {
  readonly description: string;
  readonly key: string;
  readonly previewDarkUrl: AGG$URL;
  readonly previewUrl: AGG$URL;
  readonly title: string;
  readonly " $fragmentType": "preview_growthRecommendationsInProjectList_TemplatePreview";
};
export type preview_growthRecommendationsInProjectList_TemplatePreview$key = {
  readonly " $data"?: preview_growthRecommendationsInProjectList_TemplatePreview$data;
  readonly " $fragmentSpreads": FragmentRefs<"preview_growthRecommendationsInProjectList_TemplatePreview">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "preview_growthRecommendationsInProjectList_TemplatePreview",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "title"
      },
      "action": "THROW",
      "path": "title"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "description"
      },
      "action": "THROW",
      "path": "description"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "key"
      },
      "action": "THROW",
      "path": "key"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "previewUrl"
      },
      "action": "THROW",
      "path": "previewUrl"
    },
    {
      "kind": "RequiredField",
      "field": {
        "kind": "ScalarField",
        "name": "previewDarkUrl"
      },
      "action": "THROW",
      "path": "previewDarkUrl"
    }
  ],
  "type": "JiraProjectListViewTemplateItem"
};

(node as any).hash = "f298dd557d1046875fbc288cf964ef36";

export default node;
