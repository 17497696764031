import React from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import Image from '@atlaskit/image';
import type { icon_growthRecommendationsInProjectList_CardIcon$key } from '@atlassian/jira-relay/src/__generated__/icon_growthRecommendationsInProjectList_CardIcon.graphql';

interface Props {
	keyRef: icon_growthRecommendationsInProjectList_CardIcon$key;
}

export const CardIcon = ({ keyRef }: Props) => {
	const { iconUrl, iconDarkUrl } =
		useFragment<icon_growthRecommendationsInProjectList_CardIcon$key>(
			graphql`
				fragment icon_growthRecommendationsInProjectList_CardIcon on JiraProjectListViewTemplateItem {
					iconUrl
					iconDarkUrl
				}
			`,
			keyRef,
		);

	if (!iconUrl || !iconDarkUrl) {
		return null;
	}

	return <IconImage alt="" src={iconUrl} srcDark={iconDarkUrl} />;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconImage = styled(Image)({
	width: '40px',
	height: '40px',
	borderRadius: '3px',
});

export default CardIcon;
