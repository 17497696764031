/**
 * @generated SignedSource<<d80c3635c4d5ffa7379828e8bddce78f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type icon_growthRecommendationsInProjectList_CardIcon$data = {
  readonly iconDarkUrl: AGG$URL | null | undefined;
  readonly iconUrl: AGG$URL | null | undefined;
  readonly " $fragmentType": "icon_growthRecommendationsInProjectList_CardIcon";
};
export type icon_growthRecommendationsInProjectList_CardIcon$key = {
  readonly " $data"?: icon_growthRecommendationsInProjectList_CardIcon$data;
  readonly " $fragmentSpreads": FragmentRefs<"icon_growthRecommendationsInProjectList_CardIcon">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "icon_growthRecommendationsInProjectList_CardIcon",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "iconUrl"
    },
    {
      "kind": "ScalarField",
      "name": "iconDarkUrl"
    }
  ],
  "type": "JiraProjectListViewTemplateItem"
};

(node as any).hash = "7e2f6da3f7159903d326f431a5c01c87";

export default node;
